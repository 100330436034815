import { useContext, useEffect } from "react"
import { PageHeader } from "../../components/shared/PageHeader"
import { TransactionTable } from "../../components/table/Transaction/TransactionTable"
import { PageHeaderControlContext } from "../../context/PageHeaderControlContext"

const Transactions = () => {

    const { setHeaderActions } = useContext(PageHeaderControlContext)

    useEffect(() => {
        setHeaderActions([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <PageHeader pageTitle="Transactions" />
            <TransactionTable enableSearch enableFilters enableExport/>
        </>
    )
}

export { Transactions }