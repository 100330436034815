import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Typography,
  useTheme
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {
  BarChart,
  BarChart2,
  ChevronUp,
  CreditCard,
  DollarSign,
  Layers,
  Settings,
  Users
} from "react-feather";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ExtraordinaryLogo from "../../assets/logos/Extraordinary.png";
import { MatRippleColorContext } from "../../context/MatRippleColorContext";
import { formatCurrency } from "../../utils/formatters";
import { extraordinaryColors } from "../../utils/theme";
import { useData } from "../../utils/useData";
import { UserInfoLogout } from "./UserInfoLogout";

export const DRAWER_WIDTH = 250;

const FloatInfoContainer = styled(Box)({
  marginTop: "auto",
  borderRadius: 12,
  marginLeft: 18,
  marginRight: 18,
  borderColor: "white",
  borderStyle: "solid",
  borderWidth: "2px",
  padding: 12,
  color: "white",
  display: "flex",
  flexDirection: "column"
});

const FloatLabel = styled(Typography)({ fontWeight: 500, fontSize: 14 });
const FloatDisplay = styled(Typography)({ fontWeight: 700, fontSize: 20 });
const TopupLink = styled(FloatLabel)({
  textDecoration: "underline",
  cursor: "pointer"
});

type NavItem = {
  text: string;
  to: string;
  Icon: any;
  hide?: boolean;
  subItems?: NavItem[];
  selectedColor: string;
  order?: number | undefined;
};

function sortNavItems(navItems: NavItem[]): NavItem[] {
  return navItems.sort((a, b) => {
    const orderA = a.order === undefined ? Number.MAX_SAFE_INTEGER : a.order;
    const orderB = b.order === undefined ? Number.MAX_SAFE_INTEGER : b.order;

    return orderA - orderB;
  });
}

const RouteColorMap: Record<string, string> = {
  "/users": extraordinaryColors.green.light,
  "/accountsMovedScreen": extraordinaryColors.yellow.main,
  "/transactions": extraordinaryColors.tomato.main,
  "/reporting": extraordinaryColors.deepBlue.main,
  "/funding": extraordinaryColors.orange.main,
  "/rewards": extraordinaryColors.blue.main,
  "/settings": extraordinaryColors.green.main,
  "/controllable-allowances": extraordinaryColors.yellow.main
};

export const Sidebar = () => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { employer, float } = useData();
  const [showSubItems, setShowSubItems] = useState(false);

  const { setRippleColor } = useContext(MatRippleColorContext);
  const isRootRoute = location.pathname === "/";

  useEffect(() => {
    if (isRootRoute) {
      setRippleColor(extraordinaryColors.purple.main);
    } else {
      // eslint-disable-next-line array-callback-return
      Object.keys(RouteColorMap).find((key) => {
        if (location.pathname.includes(key)) setRippleColor(RouteColorMap[key]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const navigationItems: NavItem[] = [
    {
      text: "Dashboard",
      to: "/",
      Icon: BarChart,
      selectedColor: extraordinaryColors.purple.main,
      order: 10
    },
    {
      text: "Users",
      to: "/users",
      Icon: Users,
      selectedColor: extraordinaryColors.green.light,
      order: 20
    },
    {
      text: "Accounts",
      to: "/accountsMovedScreen",
      Icon: Layers,
      selectedColor: extraordinaryColors.yellow.main,
      order: 30
    },
    {
      text: "Transactions",
      to: "/transactions",
      Icon: CreditCard,
      selectedColor: extraordinaryColors.tomato.main,
      order: 40
    },
    {
      text: "Reporting",
      to: "/reporting",
      Icon: BarChart2,
      selectedColor: extraordinaryColors.deepBlue.main,
      order: 50
    },
    {
      text: "Funding",
      to: "/funding",
      Icon: DollarSign,
      selectedColor: extraordinaryColors.orange.main,
      order: 60
    },
    {
      text: "Settings",
      to: "/settings",
      selectedColor: extraordinaryColors.green.main,
      Icon: Settings,
      order: 80
    }
  ];

  const moduleNavigationItems: NavItem[] = [
    {
      text: "Controllable Allowances",
      to: "/controllable-allowances",
      selectedColor: extraordinaryColors.yellow.main,
      Icon: Layers,
      order: 10
    },
    {
      text: "Rewards",
      to: "/rewards",
      Icon: EmojiEventsIcon,
      selectedColor: extraordinaryColors.blue.main,
      order: 20,
      hide: !employer.data?.generalOptions.isRewardsEnabled
    }
  ];

  const NavItem = ({ text, to, Icon, subItems, selectedColor }: NavItem) => {
    const isRootRoute = to === "/";
    const isSelected = isRootRoute
      ? location.pathname === to
      : location.pathname.includes(to);

    return (
      <Box
        sx={{
          borderRadius: 3,
          marginLeft: "18px",
          marginRight: "18px",
          overflow: "auto",
          backgroundColor: isSelected ? selectedColor : ""
        }}
      >
        <ListItem
          key={to}
          disablePadding
          sx={{ textDecoration: "none" }}
          onClick={() => {
            if (subItems) {
              return setShowSubItems(!showSubItems);
            }

            setRippleColor(selectedColor);

            return navigate(to);
          }}
        >
          <ListItemButton
            selected={isSelected}
            sx={{
              borderColor: "primary.main"
            }}
          >
            <ListItemIcon
              sx={{
                color: "white",
                width: 34,
                alignItems: "center"
              }}
            >
              <Icon size={16} />
            </ListItemIcon>
            <ListItemText
              primary={<Typography color="white">{text}</Typography>}
            />
            {subItems && showSubItems && <ChevronUp size={20} color="white" />}
          </ListItemButton>
        </ListItem>
        {subItems && showSubItems && (
          <List sx={{ paddingLeft: 5 }}>
            {sortNavItems(subItems).map(({ text, to, Icon }) => (
              <ListItem
                key={to}
                disablePadding
                component={Link}
                to={to}
                sx={{
                  textDecoration: "none",
                  borderColor: "primary.main"
                }}
              >
                <ListItemButton
                  selected={to === location.pathname}
                  sx={{ paddingLeft: 2 }}
                >
                  <ListItemIcon
                    sx={{
                      color: "white",
                      width: 34,
                      alignItems: "center"
                    }}
                  >
                    <Icon size={16} />
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography color="white">{text}</Typography>}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
      </Box>
    );
  };

  return (
    <Box component={"nav"}>
      <Drawer
        variant="permanent"
        anchor="left"
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: DRAWER_WIDTH,
            borderRight: `1px solid ${theme.palette.divider}`,
            backgroundImage: "none",
            boxShadow: "inherit",
            backgroundColor: "black"
          }
        }}
      >
        <List>
          <ListItem
            component={Link}
            to="/"
            sx={{ mb: 2, justifyContent: "center" }}
          >
            <img src={ExtraordinaryLogo} alt="Logo" width={165} />
          </ListItem>
          <Box>
            {sortNavItems(navigationItems)
              .filter((ni) => !ni.hide)
              .map(({ text, to, Icon, subItems, selectedColor }, i) => {
                return (
                  <NavItem
                    key={i}
                    text={text}
                    to={to}
                    Icon={Icon}
                    subItems={subItems}
                    selectedColor={selectedColor}
                  />
                );
              })}
          </Box>
          <Divider
            sx={{
              marginLeft: 4,
              marginRight: 4,
              marginTop: 2,
              marginBottom: 2
            }}
          />
          <Box>
            {sortNavItems(moduleNavigationItems)
              .filter((ni) => !ni.hide)
              .map(({ text, to, Icon, subItems, selectedColor }, i) => {
                return (
                  <NavItem
                    key={i}
                    text={text}
                    to={to}
                    Icon={Icon}
                    subItems={subItems}
                    selectedColor={selectedColor}
                  />
                );
              })}
          </Box>
        </List>

        <FloatInfoContainer>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
          >
            <FloatLabel>Funding</FloatLabel>
            <TopupLink
              onClick={() => {
                setRippleColor(extraordinaryColors.orange.main);
                navigate("/funding");
              }}
            >
              Top up
            </TopupLink>
          </Box>
          <FloatDisplay>
            {formatCurrency(float.data?.floatAmount.amount ?? 0)}
          </FloatDisplay>
        </FloatInfoContainer>
        <UserInfoLogout />
      </Drawer>
    </Box>
  );
};
