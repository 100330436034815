import { useNavigate, useParams } from "react-router-dom"
import { TabGroup } from "../../../components/shared/TabGroup"
import { PlanBenefits } from "./TabContentPages/PlanBenefits"
import { PlanOverview } from "./TabContentPages/PlanOverview"
import { PlanTiers } from "./TabContentPages/PlanTiers"
import { useData } from "../../../utils/useData"
import { LoadingSpinner } from "../../../components/shared/LoadingSpinner"
import { useContext, useEffect } from "react"
import { PageHeaderControlContext } from "../../../context/PageHeaderControlContext"
import { PageHeader } from "../../../components/shared/PageHeader"
import { NavBackButton } from "../../../components/employees/NavBackButton"
import { PlanSettings } from "./TabContentPages/PlanSettings"
import { PlanEmployees } from "./TabContentPages/PlanEmployees"
import { PlanTransactions } from "./TabContentPages/PlanTransactions"

const PlanDetails = () => {
    const { planId } = useParams();
    const { plans, accounts } = useData();
    const navigate = useNavigate();
    useEffect(() => {
        setHeaderActions([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { setHeaderActions } = useContext(PageHeaderControlContext);

    if(!planId) return <p>Navigation Error</p>
    
    if(plans.isIdle || plans.isFetching || accounts.isIdle || accounts.isFetching ) return <LoadingSpinner />

    const currentPlan = plans.data?.items.find((plan) => plan.id === planId)
    if(!currentPlan) return <p>Plan not found.</p>

    return (
        <>
            <NavBackButton onBackPress={() => navigate(`/controllable-allowances`)} />
            <PageHeader pageTitle={currentPlan?.name ?? ""} />
            <TabGroup tabLabels={["Overview", "Tiers", "Categories", "Settings", "Users", "Transactions"]}>
                <PlanOverview planId={planId} />
                <PlanTiers 
                    plan={currentPlan} 
                    renewalPeriodInMonths={currentPlan.renewalOptions.periodInMonths ?? 0}  />
                <PlanBenefits plan={currentPlan} />
                <PlanSettings plan={currentPlan} />
                <PlanEmployees planId={planId} />
                <PlanTransactions planId={planId} />
            </TabGroup>
        </>
    )
}

export { PlanDetails }