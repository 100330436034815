import Papa from "papaparse";
import {
  Card,
  Employee,
  EmployeeAccount,
  Plan,
  ServiceSector,
  Tag,
  TagCategory,
  Transaction,
  TransactionStatus
} from "../models";
import { formatCurrency } from "./formatters";

const formatDate = (dateInput: Date | string) => {
  const date = new Date(dateInput);

  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const year = date.getUTCFullYear();

  return `${day}-${month}-${year}`;
};

const convertStringToTitleCase = (string: string) => {
  return string.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
};

const convertCamelToTitleCase = (camelCaseString: string): string => {
  return camelCaseString
    .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between camelCase words
    .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
};

export const convertObjectKeysToTitleCase = (
  obj: Record<string, any>
): Record<string, any> => {
  const newObj: Record<string, any> = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      newObj[convertCamelToTitleCase(key)] = obj[key];
    }
  }
  return newObj;
};

export const downloadCsv = (csv: string, filename: string) => {
  const blob = new Blob([csv], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

const getPlanAndTierName = (
  planId: string,
  tierId: string,
  plans: Plan[] | undefined
) => {
  const plan = plans?.find((p) => p.id === planId);
  const tier = plan?.tiers.find((t) => t.id === tierId);
  if (!plan || !tier) return;

  return `${plan.name} - ${tier.name}`;
};

const getTagColumns = (
  employeeTags: Tag[] | undefined,
  tagCategories: TagCategory[] | undefined
): { [key: string]: string | null } => {
  if (!tagCategories) return {};

  const result: { [key: string]: string | null } = tagCategories.reduce(
    (acc, category) => {
      acc[category.name] = null;
      return acc;
    },
    {} as { [key: string]: string | null }
  );

  if (employeeTags) {
    for (const tag of employeeTags) {
      const category = tagCategories.find((tc) => tc.id === tag.tagCategoryId);
      if (category) {
        result[category.name] = tag.name;
      }
    }
  }

  return result;
};

const joinEmployeeData = (
  employeeId: string,
  isAnonymised: boolean,
  employees: Employee[],
  tagCategories: TagCategory[] | undefined
) => {
  const employee = employees?.find((e) => e.id === employeeId);
  return {
    ...(isAnonymised
      ? {}
      : {
          firstName: employee?.firstName,
          lastName: employee?.lastName,
          email: employee?.contact.email
        }),
    customerEmployeeId: employee?.customerEmployeeId,
    employeeStatus: employee?.status,
    ...getTagColumns(employee?.tags, tagCategories)
  };
};

export const convertEmployeesToCsv = (
  employees: Employee[],
  accounts: EmployeeAccount[],
  tagCategories: TagCategory[] | undefined,
  plans: Plan[] | undefined
): string => {
  const getSpend = (accounts: EmployeeAccount[]) => {
    const availableBalance = accounts?.reduce(
      (accountSum, account) =>
        accountSum + (account.availableBalance?.amount ?? 0),
      0
    );

    const startingBalance = accounts?.reduce(
      (balanceSum, account) =>
        balanceSum + (account?.startingBalance.amount ?? 0),
      0
    );

    return { availableBalance, startingBalance };
  };

  const csvData = employees?.map((employee) => {
    const { firstName, lastName, contact, tags, cards, status, createdAt } =
      employee;
    const employeeAccounts = accounts.filter(
      (x) => x.employeeId === employee.id
    );

    const { availableBalance, startingBalance } = getSpend(employeeAccounts);

    const csvRow = convertObjectKeysToTitleCase({
      firstName,
      lastName,
      email: contact?.email,
      phone: contact?.phone,
      availableBalance: formatCurrency(availableBalance).replaceAll(",", ""),
      startingBalance: formatCurrency(startingBalance).replaceAll(",", ""),
      plans: employeeAccounts
        ?.map((a) => getPlanAndTierName(a.planId, a.tierId, plans))
        .toString()
        .replaceAll(",", " & "),
      cardStatus:
        cards && cards.length ? cards[cards?.length - 1]?.status : "N/A", // Latest card status
      status,
      createdAt: formatDate(createdAt),
      customerEmployeeId: employee?.customerEmployeeId,
      ...getTagColumns(tags, tagCategories)
    });

    return csvRow;
  });

  const csv = Papa.unparse(csvData);
  return csv;
};

export const convertTransactionsToCsv = (
  transactions: Transaction[],
  isAnonymised: boolean,
  serviceSectors: ServiceSector[] | undefined,
  employees: Employee[] | undefined,
  tagCategories: TagCategory[] | undefined,
  plans: Plan[] | undefined
): string => {
  const csvData = transactions?.map((transaction) => {
    const {
      employeeId,
      cardAcceptor,
      billingAmount,
      status,
      serviceSectorId,
      timestamp
    } = transaction;

    const provider = cardAcceptor
      ? convertStringToTitleCase(cardAcceptor.split(/\s{2,}/)[0])
      : null;
    const csvRow = convertObjectKeysToTitleCase({
      provider: provider?.replaceAll(",", ""),
      billingAmount: formatCurrency(billingAmount?.amount).replaceAll(",", ""),
      status,
      serviceSector: serviceSectors
        ?.find((ss) => ss.id === serviceSectorId)
        ?.name?.replaceAll(",", ""),
      timestamp: formatDate(timestamp),
      ...joinEmployeeData(
        employeeId,
        isAnonymised,
        employees ?? [],
        tagCategories ?? []
      )
    });

    return csvRow;
  });

  const csv = Papa.unparse(csvData);
  return csv;
};

// We omit non-approved transactions from this report
export const convertTransactionsToTaxLabFbtCsv = (
  transactions: Transaction[],
  employees: Employee[] | undefined
): string => {
  const csvData = transactions
    ?.filter((t) => t.status === TransactionStatus.APPROVED)
    .map((transaction) => {
      const { employeeId, cardAcceptor, billingAmount, timestamp } =
        transaction;

      const employee = employees?.find((e) => e.id === employeeId);

      const provider = cardAcceptor
        ? convertStringToTitleCase(cardAcceptor.split(/\s{2,}/)[0])
        : null;
      const csvRow = convertObjectKeysToTitleCase({
        extraordinaryId: employee?.id,
        employeeId: employee?.customerEmployeeId,
        description: provider,
        firstName: employee?.firstName,
        lastName: employee?.lastName,
        valueIncl: billingAmount?.amount,
        gstRate: "",
        datePaid: formatDate(timestamp)
      });

      return csvRow;
    });

  const csv = Papa.unparse(csvData);
  return csv;
};

export const convertCardsToCsv = (
  cards: Card[],
  isAnonymised: boolean,
  employees: Employee[] | undefined,
  tagCategories: TagCategory[] | undefined,
  plans: Plan[] | undefined
): string => {
  const csvData = cards?.map((card) => {
    const { employeeId, maskedCardNumber, status, createdAt } = card;

    const csvRow = convertObjectKeysToTitleCase({
      maskedCardNumber,
      status,
      createdAt: formatDate(createdAt),
      ...joinEmployeeData(
        employeeId,
        isAnonymised,
        employees ?? [],
        tagCategories ?? []
      )
    });

    return csvRow;
  });

  const csv = Papa.unparse(csvData);
  return csv;
};
